.App {
  height: 90vh;
  display: flex;
  flex-direction: column;
}

.form-section-h1 {
  font-size: 2.2rem !important;
  line-height: 1rem !important;
  font-weight: 800 !important;
}

.section-p {
  font-size: 1.4rem !important;
  line-height: 3rem !important;
  font-weight: 100 !important;
}
