.Header {
  padding: 1.5rem 1.5rem;
}

.Header__branding {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Header__branding img {
  height: 4.5rem;
  padding: 0 1rem;
}
